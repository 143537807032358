
import { defineComponent, ref, computed, onUpdated, watch } from "vue";
import Datatable from "@/components/datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import { Client } from "@/core/types/ClientTypes";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import NewClientModal from "@/components/modals/forms/NewClientModal.vue";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import { boolean } from "yup/lib/locale";

export default defineComponent({
  name: "sa-licensed-distributor-clients",
  props: ["accountid"],
  components: {
    Datatable,
    NewClientModal,
    TableSkeleton,
  },
  setup(props) {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const route = useRoute();
    const routeName = computed(() => route.name);
    let displayModal = ref<boolean>(false);
    const isDataReady = ref(false);
    const licensedDistInfo = computed(() => {
      return store.getters.getLicensedDistributorInfo(String(props.accountid));
    });
    const tableHeader = ref([
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Primary Contact",
        key: "primaryContactName",
        sortable: true,
      },
      {
        name: "Email",
        key: "primaryContactEmail",
        sortable: true,
      },
      {
        name: "# of Employees",
        key: "numOfEmployees",
        sortable: true,
      },
      {
        name: "Content Expert",
        key: "primaryContentExpert",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const tableData = computed(() => {
      return store.getters.getClientList;
    });

    const initCustomers = ref<Array<Client>>([]);

    store
      .dispatch(Actions.GET_LICENSED_DISTRIBUTOR_CLIENTS, props.accountid)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving clients.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    onUpdated(() => {
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    watch(tableData, () => {
      isDataReady.value = true;
    });

    function openModal() {
      displayModal.value = true;
    }

    const deleteClient = (id) => {
      Swal.fire({
        title: "Are you sure you want to delete this client?",
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          store
            .dispatch(Actions.DELETE_CLIENT, id)
            .then(() => {
              Swal.fire("Deleted!", "", "success");
              for (let i = 0; i < tableData.value.length; i++) {
                if (tableData.value[i].id === id) {
                  tableData.value.splice(i, 1);
                }
              }
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "There was a problem deleting the client.",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<Client> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (
          !Number.isInteger(obj[key]) &&
          !(typeof obj[key] === boolean) &&
          !(typeof obj[key] === "object")
        ) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      licensedDistInfo,
      currentUser,
      routeName,
      isDataReady,
      initCustomers,
      tableData,
      tableHeader,
      search,
      displayModal,
      deleteClient,
      searchItems,
      openModal,
    };
  },
});
