<template>
  <!--begin::details View-->
  <div class="card mb-5 mb-xl-10" id="kt_profile_details_view">
    <!--begin::Card header-->
    <div class="card-header cursor-pointer">
      <!--begin::Card title-->
      <div class="card-title m-0">
        <h3 class="fw-bolder m-0">Client List</h3>
      </div>
      <!--end::Card title-->
      <!--begin::Card toolbar-->
      <div class="card-toolbar">
        <a
          href="#"
          class="btn btn-sm btn-flex btn-light-primary"
          data-bs-toggle="modal"
          data-bs-target="#modal_new_client"
        >
          <span class="svg-icon svg-icon-3">
            <inline-svg src="/media/icons/duotune/general/gen035.svg" />
          </span>
          Add new client</a
        >
      </div>
      <!--end::Card toolbar-->
      <!--begin::Action-->
    </div>
    <!--end::Action-->
    <!--begin::Card header-->

    <!--begin::Card body-->
    <div class="card-body pt-0" v-if="isDataReady">
      <Datatable
        :table-data="tableData"
        :table-header="tableHeader"
        :enable-items-per-page-dropdown="true"
      >
        <template v-slot:cell-name="{ row: client }">
          {{ client.name }}
        </template>
        <template v-slot:cell-primaryContactName="{ row: client }">
          {{ client.primaryContactName }}
        </template>
        <template v-slot:cell-primaryContactEmail="{ row: client }">
          {{ client.primaryContactEmail }}
        </template>
        <template v-slot:cell-numOfEmployees="{ row: client }">
          {{ client.numOfEmployees }}
        </template>
        <template v-slot:cell-primaryContentExpert="{ row: client }">
          {{ client.primaryContentExpert }}
        </template>
        <template v-slot:cell-actions="{ row: client }">
          <router-link
            :to="{
              name: 'client-overview',
              params: {
                clientid: Number(client.clientId),
              },
            }"
            class="
              btn btn-icon btn-bg-light btn-active-color-primary btn-sm
              me-1
            "
          >
            <span class="svg-icon svg-icon-3">
              <inline-svg src="../media/icons/duotune/art/art005.svg" /> </span
          ></router-link>

          <a
            href="#"
            class="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            @click="deleteClient(client.clientId)"
          >
            <span class="svg-icon svg-icon-3">
              <inline-svg src="../media/icons/duotune/general/gen027.svg" />
            </span>
          </a>
        </template>
      </Datatable>
    </div>
    <div v-else>
      <TableSkeleton></TableSkeleton>
    </div>
    <!--end::Card body-->
  </div>
  <!--end::details View-->
  <NewClientModal
    :displayModal="displayModal"
    :userRole="currentUser.role"
    :licensedDistAspNetUserId="licensedDistInfo.distAspNetUserId"
    :licensedDistAssocAspNetUserId="0"
    :routeName="routeName"
  ></NewClientModal>
</template>

<script lang="ts">
import { defineComponent, ref, computed, onUpdated, watch } from "vue";
import Datatable from "@/components/datatable/KTDatatable.vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { Actions } from "@/store/enums/StoreEnums";
import { Client } from "@/core/types/ClientTypes";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import NewClientModal from "@/components/modals/forms/NewClientModal.vue";
import TableSkeleton from "@/components/widgets/skeletons/TableSkeleton.vue";
import { boolean } from "yup/lib/locale";

export default defineComponent({
  name: "sa-licensed-distributor-clients",
  props: ["accountid"],
  components: {
    Datatable,
    NewClientModal,
    TableSkeleton,
  },
  setup(props) {
    const store = useStore();
    const currentUser = store.getters.currentUser;
    const route = useRoute();
    const routeName = computed(() => route.name);
    let displayModal = ref<boolean>(false);
    const isDataReady = ref(false);
    const licensedDistInfo = computed(() => {
      return store.getters.getLicensedDistributorInfo(String(props.accountid));
    });
    const tableHeader = ref([
      {
        name: "Name",
        key: "name",
        sortable: true,
      },
      {
        name: "Primary Contact",
        key: "primaryContactName",
        sortable: true,
      },
      {
        name: "Email",
        key: "primaryContactEmail",
        sortable: true,
      },
      {
        name: "# of Employees",
        key: "numOfEmployees",
        sortable: true,
      },
      {
        name: "Content Expert",
        key: "primaryContentExpert",
        sortable: true,
      },
      {
        name: "Actions",
        key: "actions",
      },
    ]);

    const tableData = computed(() => {
      return store.getters.getClientList;
    });

    const initCustomers = ref<Array<Client>>([]);

    store
      .dispatch(Actions.GET_LICENSED_DISTRIBUTOR_CLIENTS, props.accountid)
      .then(() => {
        //
      })
      .catch(() => {
        Swal.fire({
          text: store.getters.getErrors[0],
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "There was a problem retrieving clients.",
          customClass: {
            confirmButton: "btn fw-bold btn-light-danger",
          },
        });
      });

    onUpdated(() => {
      initCustomers.value.splice(0, tableData.value.length, ...tableData.value);
    });

    watch(tableData, () => {
      isDataReady.value = true;
    });

    function openModal() {
      displayModal.value = true;
    }

    const deleteClient = (id) => {
      Swal.fire({
        title: "Are you sure you want to delete this client?",
        showCancelButton: true,
        confirmButtonText: "Delete",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          store
            .dispatch(Actions.DELETE_CLIENT, id)
            .then(() => {
              Swal.fire("Deleted!", "", "success");
              for (let i = 0; i < tableData.value.length; i++) {
                if (tableData.value[i].id === id) {
                  tableData.value.splice(i, 1);
                }
              }
            })
            .catch(() => {
              Swal.fire({
                text: store.getters.getErrors[0],
                icon: "error",
                buttonsStyling: false,
                confirmButtonText: "There was a problem deleting the client.",
                customClass: {
                  confirmButton: "btn fw-bold btn-light-danger",
                },
              });
            });
        }
      });
    };

    const search = ref<string>("");
    const searchItems = () => {
      tableData.value.splice(0, tableData.value.length, ...initCustomers.value);
      if (search.value !== "") {
        let results: Array<Client> = [];
        for (let j = 0; j < tableData.value.length; j++) {
          if (searchingFunc(tableData.value[j], search.value)) {
            results.push(tableData.value[j]);
          }
        }
        tableData.value.splice(0, tableData.value.length, ...results);
      }
    };

    const searchingFunc = (obj, value): boolean => {
      for (let key in obj) {
        if (
          !Number.isInteger(obj[key]) &&
          !(typeof obj[key] === boolean) &&
          !(typeof obj[key] === "object")
        ) {
          if (obj[key].indexOf(value) != -1) {
            return true;
          }
        }
      }
      return false;
    };

    return {
      licensedDistInfo,
      currentUser,
      routeName,
      isDataReady,
      initCustomers,
      tableData,
      tableHeader,
      search,
      displayModal,
      deleteClient,
      searchItems,
      openModal,
    };
  },
});
</script>
